<!-- =========================================================================================
    File Name: ItemListView.vue
    Description: Item Component - List VIew
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vx-card class="list-view-item overflow-hidden" v-on="$listeners">
    <template slot="no-body">
      <div class="vx-row item-details no-gutter">
        <!-- IMG COL -->
        <div
          class="vx-col sm:w-1/4 w-full item-img-container bg-white flex items-center justify-center cursor-pointer"
          @click="navigate_to_detail_view(item)"
        >
          <img :src="item.univ_logo" class="grid-view-img p-4" />
        </div>

        <!-- ITEM NAME & DESC COL -->
        <div class="vx-col sm:w-1/2">
          <div class="p-4 pt-6">
            <slot name="item-meta">
              <h6
                class="item-name text-primary font-semibold mb-1 hover:text-dark cursor-pointer"
                @click="navigate_to_detail_view(item)"
              >
                {{ item.course_name }}
              </h6>
              <p class="text-md mb-4">
                <span class=" flex items-start ">
                  <feather-icon
                    icon="HomeIcon"
                    svgClasses="w-4 h-4"
                    class="mt-2"
                  />
                  <span class=" ml-2">{{ item.university }}</span>
                </span>
              </p>
              <p class="item-description ">
                {{ strippedContent(item.course_about) }}
              </p>
            </slot>
          </div>
        </div>

        <!-- PURCHASE COL -->
        <div
          class="vx-col sm:w-1/4 w-full flex items-center sm:border border-0 border-solid d-theme-border-grey-light border-r-0 border-t-0 border-b-0"
        >
          <div class="p-4 flex flex-col w-full lg:ml-5">
            <div class="my-6 my-sm">
              <span class="text-grey flex items-start justify-left">
                <feather-icon
                  icon="MapPinIcon"
                  svgClasses="w-4 h-4"
                  class="mt-2"
                />
                <span class="text-md ml-2">{{ item.city }}</span>
              </span>

              <span class="text-grey flex items-start justify-left mt-1">
                <feather-icon
                  icon="AwardIcon"
                  svgClasses="w-4 h-4"
                  class="mt-2"
                />
                <span class="text-md ml-2">{{ item.course_type }}</span>
              </span>

              <span class="text-grey flex items-start justify-left mt-1">
                <feather-icon
                  icon="ClockIcon"
                  svgClasses="w-4 h-4"
                  class="mt-2"
                />
                <span class="text-md ml-2">{{ item.duration }}</span>
              </span>
            </div>

            <!-- SLOT: ACTION BUTTONS -->
            <slot name="action-buttons" />
          </div>
        </div>
      </div>
    </template>
  </vx-card>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    isInCart() {
      return itemId => this.$store.getters["eCommerce/isInCart"](itemId);
    },
    isInWishList() {
      return itemId => this.$store.getters["eCommerce/isInWishList"](itemId);
    }
  },
  methods: {
    strippedContent(string) {
      return string.replace(/<\/?[^>]+>/gi, " ");
    },
    // eslint-disable-next-line no-unused-vars
    toggleItemInWishList(item) {
      // this.$store.dispatch('eCommerce/toggleItemInWishList', item)
    },
    // eslint-disable-next-line no-unused-vars
    additemInCart(item) {
      // this.$store.dispatch('eCommerce/additemInCart', item)
    },
    // eslint-disable-next-line no-unused-vars
    cartButtonClicked(item) {
      // this.isInCart(item.objectID) ? this.$router.push('/apps/eCommerce/checkout').catch(() => {}) : this.additemInCart(item)
    },
    navigate_to_detail_view(val) {
      this.$store.dispatch("setcourseUpdateId", val.course_id);
      // this.$router.push("/CourseView");
      this.$router.push({ name: 'CourseView', params: { id: val.course_name }})
    }
  }
};
</script>

<style lang="scss" scoped>
.list-view-item {
  .item-name,
  .item-description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .item-name {
    -webkit-line-clamp: 2;
  }

  .item-description {
    -webkit-line-clamp: 5;
  }

  .grid-view-img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    transition: 0.35s;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);

    .grid-view-img {
      opacity: 0.9;
    }
  }
}

@media (min-width: 992px){
  .lg\:ml-5 {
    margin-left:3rem !important;
}
}
@media only screen and (max-width: 600px) {
  .my-sm{
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }
}
</style>
